import React, {
    useMemo
} from "react";
import {
    useSortable
} from "@dnd-kit/sortable";
import {
    CSS
} from "@dnd-kit/utilities";
import {
    Button
} from "react-bootstrap";

import EventCard from "./EventCard";

function DraggableEventCard({ event, onRemove }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: event.id
    });

    const style = useMemo(() => ({
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 100 : 0,
        position: "relative",
        cursor: "grab",
    }), [transform, transition, isDragging]);

    return (
        <div
            ref={setNodeRef}
            style={style}
        >
            <div className="position-relative">
                { onRemove && (
                    <div style={{
                        position: "absolute",
                        zIndex: 1,
                        right: "15px",
                        top: "15px",
                    }}>
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={() => onRemove(event)}
                        >
                            <i className="fa-solid fa-trash fa-fw"/>
                        </Button>
                    </div>
                )}
                <div
                    {...attributes}
                    {...listeners}
                >
                    <EventCard
                        event={event}
                        noLive
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(DraggableEventCard);
