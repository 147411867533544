import React from "react";

import {
    rideMarkerTypes
} from "../../components/RideMarkerCard";
import FormHelpTooltip from "../../../../../../components/FormHelpTooltip";

function EditMarkerForm({ rideMarker, setRideMarker }) {
    if(!rideMarker) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-7">
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <select
                            className="form-control"
                            id="type"
                            value={ rideMarker.type }
                            onChange={ (event) => setRideMarker({ type: event.target.value }) }
                        >
                            { rideMarker.new && (
                                <option value={ -1 }/>
                            )}
                            { rideMarkerTypes.map((type) => (
                                <option
                                    key={ type.id }
                                    value={ type.id }
                                >
                                    { type.name }
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="form-group">
                        <label htmlFor="triggerRadius" className="w-100">
                            Activeringsstraal
                            <FormHelpTooltip className="float-right">
                                De afstand waarop de marker geactiveerd wordt in meter.
                            </FormHelpTooltip>
                        </label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="triggerRadius"
                                value={rideMarker.triggerRadius}
                                onChange={(event) => setRideMarker({triggerRadius: event.target.value})}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">m</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="title">Titel</label>
                <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={ rideMarker.title }
                    onChange={ (event) => setRideMarker({ title: event.target.value }) }
                />
            </div>
            <div className="form-group">
                <label htmlFor="description">Beschrijving</label>
                <textarea
                    className="form-control"
                    id="description"
                    value={ rideMarker.description }
                    onChange={ (event) => setRideMarker({ description: event.target.value }) }
                />
            </div>
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id="bypassMute"
                    checked={ rideMarker.bypassMute }
                    onChange={ (event) => setRideMarker({ bypassMute: event.target.checked }) }
                />
                <label className="custom-control-label" htmlFor="bypassMute">
                    Navigatie dempen doorbreken
                    <FormHelpTooltip className="ml-2">
                        Als de gebruiker navigatie heeft gedempt, wordt deze marker alsnog afgespeeld.
                    </FormHelpTooltip>
                </label>
            </div>
        </React.Fragment>
    );
}

export default React.memo(EditMarkerForm);
