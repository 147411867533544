import React, {
    useContext
} from "react";
import {
    Redirect,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import AdminNavbar from "../../components/layout/AdminNavbar";
import Loading from "../../components/Loading";

import AdminUsers from "./users/AdminUsers";
import AdminUserDetail from "./users/detail/AdminUserDetail";
import Dashboard from "./dashboard/Dashboard";

function AdminRouter() {
    const match = useRouteMatch();
    const { user } = useContext(AuthenticatedUserContext);

    if(!user) {
        return (
            <Loading/>
        );
    }
    if(!user.globalAdmin) {
        return (
            <Redirect to="/"/>
        );
    }

    return (
        <React.Fragment>
            <AdminNavbar/>
            <div className="container" style={{marginTop: "80px"}}>
                <Switch>
                    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                    <Route path={`${match.url}/users`} component={AdminUsers}/>
                    <Route path={`${match.url}/user/:userId`} component={AdminUserDetail}/>
                    <Route path="/">
                        <Redirect to={`${match.url}/dashboard`}/>
                    </Route>
                </Switch>
            </div>
        </React.Fragment>
    );
}

export default React.memo(AdminRouter);
