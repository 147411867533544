import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../../components/Loading";
import Helmet from "../../../components/Helmet";
import {
    withOrganisationsContext
} from "../../../context/OrganisationsContext";
import DateFormatter from "../../../components/DateFormatter";

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null,
            error: null
        };
    }

    componentDidMount() {
        this.getEvents();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(this.props.organisationsContext.currentOrganisation) {
            if(!prevProps.organisationsContext.currentOrganisation
                || (prevProps.organisationsContext.currentOrganisation.id !== this.props.organisationsContext.currentOrganisation.id)) {
                this.getEvents();
            }
        }
    }

    getEvents() {
        if(!this.props.organisationsContext.currentOrganisation) {
            return;
        }
        axios.post("/getEventsForOrganisation", {
            organisationId: this.props.organisationsContext.currentOrganisation.id,
            showPrivate: 1
        })
            .then((response) => {
                if(response.data.valid) {
                    const events = response.data.events.sort((event1, event2) => {
                        const event1Date = event1.startDate || event1.creationDate;
                        const event2Date = event2.startDate || event2.creationDate;
                        if(event1Date !== null && event2Date !== null) {
                            if(event1Date > event2Date) return 1;
                            if(event1Date < event2Date) return -1;
                        }
                        if(event1.name > event2.name) return 1;
                        if(event1.name < event2.name) return -1;
                        return 0;
                    });
                    this.setState({ events });
                } else {
                    if(response.data.error === "SESSION_EXPIRED") {
                        this.setState({ error: "Sessie is verlopen." });
                    } else {
                        this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        const {
            error,
            events
        } = this.state;
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        if(!currentOrganisation) {
            return null;
        }
        const baseUrl = "/organisation/" + currentOrganisation.codeName;
        return (
            <React.Fragment>
                <Helmet title="Evenementen"/>
                <Title
                    preTitle="Overzicht"
                    noBottom
                    preChildren={
                        <div className="float-right mt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id="add">Nieuw evenement</Tooltip>
                            }>
                                <Link to={ baseUrl + "/events/add" } className="btn btn-primary">
                                    <i className="fas fa-plus fa-fw"/>
                                </Link>
                            </OverlayTrigger>
                        </div>
                    }
                >
                    Evenementen
                </Title>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : (
                    <Table size="sm" hover={ events && events.length > 0 }>
                        <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th scope="col" className="text-center">
                                    #
                                </th>
                                <th scope="col" className="text-left">
                                    Evenement
                                </th>
                                <th scope="col" className="text-center">
                                    Aantal deelnemers
                                </th>
                                <th scope="col" className="text-left" style={{ minWidth: "230px" }}>
                                    Datum
                                </th>
                                <th scope="col" className="text-center">
                                    Zichtbaar
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { events == null ? (
                                <tr>
                                    <td colSpan={ 6 }>
                                        <Loading/>
                                    </td>
                                </tr>
                            ) : events.length === 0 ? (
                                <tr>
                                    <td colSpan={ 6 } className="text-center">
                                        <h1><i className="fas fa-calendar-alt"/></h1>
                                        <h3>Geen evenementen</h3>
                                        <p>Er zijn nog geen evenementen aangemaakt in deze organisatie.</p>
                                        <Link to={ baseUrl + "/events/add" } className="btn btn-primary">
                                            <i className="fas fa-plus fa-fw"/> Eerste evenement aanmaken
                                        </Link>
                                    </td>
                                </tr>
                            ) : events.map( (event) => {
                                const url = baseUrl + "/events/" + event.id;
                                return (
                                    <tr key={event.id} className="table-clickable-row">
                                        <td className="align-middle text-center">
                                            <Link to={ url }>
                                                { event.id }
                                            </Link>
                                        </td>
                                        <td className="align-middle">
                                            <Link to={ url }>
                                                { event.name }
                                            </Link>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Link to={ url }>
                                                { event.participantAmount }
                                            </Link>
                                        </td>
                                        <td className="align-middle">
                                            <Link to={ url }>
                                                { event.startDate ? (
                                                    event.startDate === event.endDate || !event.endDate ? (
                                                        <DateFormatter date={ event.startDate } format="DD-MM-YYYY"/>
                                                    ) : (
                                                        <React.Fragment>
                                                            <DateFormatter date={ event.startDate } format="DD-MM-YYYY"/>
                                                            {" - "}
                                                            <DateFormatter date={ event.endDate } format="DD-MM-YYYY"/>
                                                        </React.Fragment>
                                                    )
                                                ) : (
                                                    <i className="text-muted">Geen</i>
                                                ) }
                                            </Link>
                                        </td>
                                        <td className="align-middle text-center">
                                            <Link to={ url }>
                                                <OverlayTrigger overlay={
                                                    <Tooltip id="publiclyVisible">{ event.publiclyVisible ? "Publiekelijk zichtbaar voor iedereen" : "Alleen zichtbaar voor deelnemers" }</Tooltip>
                                                }>
                                                    { event.publiclyVisible ? (
                                                        <i className="fas fa-eye fa-fw text-success"/>
                                                    ) : (
                                                        <i className="fas fa-eye-slash fa-fw text-danger"/>
                                                    ) }
                                                </OverlayTrigger>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(React.memo(Events));
