import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner,
    Modal
} from "react-bootstrap";

function AdminUserDetailEdit({ user, setUser }) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userEdit, setUserEdit] = useState(null);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    useEffect(() => {
        setUserEdit({
            name: user.name,
            email: user.email
        });
    }, [user]);

    const onChange = useCallback((event) => {
        setUserEdit({ ...userEdit, [event.target.id]: event.target.value });
    }, [userEdit]);

    const onSave = useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            const response = await axios.post("/admin/setUser", {
                ...userEdit,
                userId: user.id,
            });
            setUser(response.data.user);
            setSuccess("Gebruiker is aangepast.");
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [user, userEdit, setUser]);

    const sendResetPassword = useCallback(async () => {
        setError(null);
        setLoading(true);
        
        try {
            const response = await axios.post("/initiateForgotPassword", { email: user.email });
            
            if(response.data.valid) {
                setSuccess("Er is een link verstuurd waarmee het wachtwoord opnieuw ingesteld kan worden.");
            } else {
                setError("Er ging iets fout. Probeer het later opnieuw.");
            }
        } catch (requestError) {
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
            setShowResetPasswordModal(false);
        }
    }, [user.email]);

    const handleResetPassword = useCallback(() => {
        setShowResetPasswordModal(true);
    }, []);

    if(!userEdit) {
        return null;
    }

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <Modal show={showResetPasswordModal} onHide={() => setShowResetPasswordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Wachtwoord resetten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet je zeker dat je het wachtwoord van { user.name } wilt resetten?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowResetPasswordModal(false)}
                        disabled={loading}
                    >
                        Annuleren
                    </Button>
                    <Button
                        variant="primary"
                        onClick={sendResetPassword}
                        disabled={loading}
                    >
                        {loading && (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        )}
                        Verstuur reset link
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Gegevens</h5>
                    <div className="form-group mb-3">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            value={userEdit.name}
                            onChange={onChange}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={userEdit.email}
                            onChange={onChange}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <p className="mb-2">Wachtwoord resetten</p>
                        <Button
                            variant="primary"
                            onClick={handleResetPassword}
                            disabled={loading}
                        >
                            {loading && (
                                <Spinner animation="border" size="sm" className="mr-2"/>
                            )}
                            Verstuur reset link
                        </Button>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
                <div>
                    <Button
                        variant="primary"
                        onClick={onSave}
                        disabled={loading}
                    >
                        {loading && (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        )}
                        Opslaan
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(AdminUserDetailEdit);
