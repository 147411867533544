import React from "react";
import {
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import DashboardOverview from "./DashboardOverview";
import DashboardDiscover from "./DashboardDiscover";

function Dashboard({ match }) {
    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Title preTitle="Admin" noBottom>
                Dashboard
            </Title>
            <TabBar>
                <TabBarItem
                    title="Dashboard"
                    to="/admin/dashboard"
                    exact
                >
                    Overzicht
                </TabBarItem>
                <TabBarItem
                    title="Dashboard"
                    to="/admin/dashboard/discover"
                    exact
                >
                    Ontdek evenementen
                </TabBarItem>
            </TabBar>
            <Switch>
                <Route path={`${match.url}`} exact component={DashboardOverview}/>
                <Route path={`${match.url}/discover`} component={DashboardDiscover}/>
            </Switch>
        </React.Fragment>
    );
}

export default React.memo(Dashboard);
