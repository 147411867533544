import React, {
    useContext,
    useMemo
} from "react";

import Helmet from "../../components/Helmet";
import OrganisationsContext from "../../context/OrganisationsContext";

import banner from "../../img/banner.jpg";

function Dashboard() {
    const organisationsContext = useContext(OrganisationsContext);

    const organisation = useMemo(() => {
        return organisationsContext.currentOrganisation;
    }, [organisationsContext.currentOrganisation]);

    const bannerUrl = useMemo(() => {
        const organisation = organisationsContext.currentOrganisation;
        if(!organisation) {
            return null;
        }
        return organisation.banner ? organisation.banner.url : banner;
    }, [organisationsContext.currentOrganisation]);
    const logoUrl = useMemo(() => {
        const organisation = organisationsContext.currentOrganisation;
        if(!organisation) {
            return null;
        }
        return organisation.logo ? organisation.logo.url : null;
    }, [organisationsContext.currentOrganisation]);

    if(!organisation) {
        return null;
    }
    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <div
                className="card text-white"
                style={{
                    backgroundImage: `url(${ bannerUrl })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: "none",
                    overflow: "hidden"
                }}
            >
                <div className="card-body" style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}>
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
                        { logoUrl ? (
                            <img
                                src={ logoUrl }
                                alt={ organisation.name }
                                className="w-100"
                                style={{
                                    objectFit: "contain",
                                    maxHeight: "200px",
                                    maxWidth: "400px",
                                }}
                            />
                        ) : (
                            <h1>{ organisation.name }</h1>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Dashboard);
