import React, {
    useMemo
} from "react";
import PropTypes from "prop-types";

import defaultBanner from "../../../../img/banner.jpg";

function EventCard({ event, noLive, style = {} }) {
    const bannerUrl = useMemo(() => {
        if(!event) {
            return null;
        }
        if(!event.banner) {
            return defaultBanner;
        }
        return event.banner.alternatives?.banner_card_1080x540?.url ?? event.banner.url;
    }, [event]);
    const logoUrl = useMemo(() => {
        if(!event?.logo) {
            return null;
        }
        return event.logo.alternatives?.logo_1080_width?.url ?? event.logo.url;
    }, [event]);

    return (
        <div
            className={ "card-event w-100" + (event.live ? " live" : "") }
            style={{
                ...style,
                backgroundImage: `url(${bannerUrl})`,
            }}
        >
            { !noLive && event.live && (
                <div className="live-badge">
                    LIVE
                </div>
            )}
            { logoUrl ? (
                <div
                    className="event-logo"
                    style={{
                        backgroundImage: `url(${logoUrl})`,
                    }}
                />
            ) : (
                <div className="flex-grow-1"/>
            )}
            <div className="event-title-container">
                <h6 className="event-title">
                    { event.name }
                </h6>
            </div>
        </div>
    );
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
};

export default React.memo(EventCard);
