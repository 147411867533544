import React, {
    useContext
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import OrganisationsDropdown from "./OrganisationsDropdown";

function AdminNavbarItem({ to, children, additionalUrls = [] }) {
    const routeMatch = useRouteMatch({
        path: [to, ...additionalUrls],
    });
    return (
        <li className="nav-item">
            <Link to={ to } className={"nav-link" + (routeMatch ? " active" : "")}>
                { children }
            </Link>
        </li>
    );
}

function AdminNavbar() {
    const { user, logoutFunction } = useContext(AuthenticatedUserContext);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top navbar-only navbar-admin">
            <div className="container">
                <ul className="navbar-nav flex-grow-1 flex-row">
                    <AdminNavbarItem to="/admin/dashboard">
                        <i className="fas fa-tachometer-alt mr-2"/>
                        Dashboard
                    </AdminNavbarItem>
                    <AdminNavbarItem to="/admin/organisations" additionalUrls={["/admin/organisation"]}>
                        <i className="fas fa-building mr-2"/>
                        Organisaties
                    </AdminNavbarItem>
                    <AdminNavbarItem to="/admin/users" additionalUrls={["/admin/user"]}>
                        <i className="fas fa-users mr-2"/>
                        Gebruikers
                    </AdminNavbarItem>
                </ul>
                <ul className="navbar-nav ml-auto flex-grow-1 flex-row justify-content-end">
                    <OrganisationsDropdown/>
                    <NavDropdown title={ user && user.name } id="user">
                        <NavDropdown.Item onClick={ logoutFunction }>
                            Uitloggen
                        </NavDropdown.Item>
                    </NavDropdown>
                </ul>
            </div>
        </nav>
    );
}

export default React.memo(AdminNavbar);
