import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

import Loading from "../../../components/Loading";
import EventCard from "./components/EventCard";
import DraggableEventCard from "./components/DraggableEventCard";
import SelectEventModal from "./modal/SelectEventModal";

function DashboardDiscover() {
    const [events, setEvents] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [showAddEventModal, setShowAddEventModal] = useState(false);

    const [draggingEvent, setDraggingEvent] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const refreshHighlightedEvents = useCallback(async () => {
        setEvents(null);
        setError(null);
        try {
            const response = await axios.get("/adminGetHighlightedEvents");
            setEvents(response.data.highlightedEvents);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);
    const handleHighlightedEvents = useCallback(async () => {
        setSaveError(null);
        setSaving(true);
        try {
            const response = await axios.post("/adminSetHighlightedEvents", {
                highlightedEvents: JSON.stringify(events.map((event) => event.id)),
            });
            setEvents(response.data.highlightedEvents);
        } catch(requestError) {
            console.error(requestError);
            setSaveError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setSaving(false);
        }
    }, [events]);

    useEffect(() => {
        refreshHighlightedEvents();
    }, [refreshHighlightedEvents]);

    const handleEventAdd = useCallback((event) => {
        setEvents((prevEvents) => [event, ...prevEvents]);
    }, []);
    const handleEventRemove = useCallback((event) => {
        setEvents((prevEvents) => prevEvents.filter((searchEvent) => searchEvent.id !== event.id));
    }, []);

    const handleDragStart = useCallback((event) => {
        setDraggingEvent(events.find((searchEvent) => searchEvent.id === event.active.id));
    }, [events]);
    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        if (active.id === over.id) {
            return;
        }
        setEvents((prevEvents) => {
            const oldIndex = prevEvents.findIndex((event) => event.id === active.id);
            const newIndex = prevEvents.findIndex((event) => event.id === over.id);
            return arrayMove(prevEvents, oldIndex, newIndex);
        });
    }, []);

    return (
        <React.Fragment>
            <SelectEventModal
                show={showAddEventModal}
                handleClose={() => setShowAddEventModal(false)}
                onEventSelected={handleEventAdd}
                existingEvents={events}
            />
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !events ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    { saveError && (
                        <Alert variant="danger">{ saveError }</Alert>
                    )}
                    <Button
                        variant="primary"
                        className="mb-3 mr-2"
                        onClick={() => setShowAddEventModal(true)}
                        disabled={saving}
                    >
                        <i className="fa-solid fa-plus mr-2"/>
                        Evenement toevoegen
                    </Button>
                    <Button
                        variant="secondary"
                        className="mb-3 mr-2"
                        onClick={handleHighlightedEvents}
                        disabled={saving}
                    >
                        { saving ? (
                            <Spinner animation="border" className="mr-2" size="sm"/>
                        ) : (
                            <i className="fa-solid fa-save mr-2"/>
                        )}
                        Opslaan
                    </Button>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={events}
                            strategy={verticalListSortingStrategy}
                        >
                            <div style={{ maxWidth: "400px" }}>
                                { events.map((event) => (
                                    <DraggableEventCard
                                        key={event.id}
                                        event={event}
                                        onRemove={handleEventRemove}
                                    />
                                ))}
                            </div>
                        </SortableContext>
                        <DragOverlay>
                            { draggingEvent && (
                                <EventCard
                                    event={draggingEvent}
                                    style={{
                                        cursor: "grabbing",
                                        opacity: 0.5,
                                    }}
                                />
                            )}
                        </DragOverlay>
                    </DndContext>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(DashboardDiscover);
