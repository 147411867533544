import React from "react";
import axios from "axios";
import PropTypes from "prop-types";

import BaseSelectObjectModal from "../../../../components/modal/BaseSelectObjectModal";

class SelectEventModal extends BaseSelectObjectModal {
    abstractGetTitle() {
        return "Evenement selecteren";
    }

    async abstractLoadObjects() {
        const response = await axios.get("/adminGetAllEvents");
        return response.data.events;
    }

    async abstractSave(selectedObjectId) {
        this.props.onEventSelected(this.state.objects.find((object) => object.id === selectedObjectId));
    }

    abstractGetExistingObjects() {
        return this.props.existingEvents;
    }

    abstractGetObjectListItemContent(object) {
        return (
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    { object.name }
                </div>
            </div>
        );
    }

    abstractGetNoObjectsMessage() {
        return "Geen evenementen gevonden.";
    }

    abstractAlreadyAddedMessage() {
        return "Dit evenement is al toegevoegd.";
    }

    matchObject(object, filter) {
        return object.name.toLowerCase().includes(filter);
    }
}
SelectEventModal.propTypes = {
    ...BaseSelectObjectModal.propTypes,
    onEventSelected: PropTypes.func.isRequired,
    existingEvents: PropTypes.array
};

export default SelectEventModal;
